import React, { useState } from "react";
import server from "../../api/Server";


const FormComponent = ({setOpenForm}) => {
  let [name, setName]= useState(null);
  let [company_name, setCompanyName]= useState(null);
  let [company_email, setCompanyEmail]= useState(null);
  let [contact_number, setContactNumber]= useState(null);
  let [requirements, setRequirements]= useState(null);
  let [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });
  const [success, setSuccess] = useState(false);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };
  
  const handleSave = async () => {
    try {
      setLoading(true);
      if (!name || !company_name || !company_email || !contact_number || !requirements) {
        setMessage({ type: "error", text: "All fields are required!" });
        setLoading(false);
        return;
      }
      if (!isValidEmail(company_email)) {
        setMessage({ type: "error", text: "Incorrect email format!" });
        setLoading(false);
        return;
      }
      if (!isValidPhoneNumber(contact_number)) {
        setMessage({ type: "error", text: "Incorrect phone number!" });
        setLoading(false);
        return;
      }

      let obj = {
        name, company_name, company_email, contact_number, requirements,
      };

      let res = await server.demoDetails(obj);

      if (res.status !== 200) {
        setMessage({ type: "error", text: "Some error occurred. Please try again later." });
        setLoading(false);
        return;
      }

      setMessage({ type: "success", text: "Demo Details Shared Successfully!" });
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setLoading(false);
        setOpenForm(false);
      }, 3000);
    } catch (error) {
      setMessage({ type: "error", text: "An error occurred. Please try again." });
      setLoading(false);
    }
  };
  return (
    <div className="h-[70vh]" style={{ justifyContent: "center", height: "100%", background: "white", borderRadius: "10px", padding: "2px"}}>
      <div onClick={() => setOpenForm(false)} style={{ textAlign: "end", width: "100%" }}>
                <span style={{fontSize: "14px", background: "#e7e7e7", padding: "8px 10px", borderRadius: "50%",marginRight: "-20px",margin: "-13px",zIndex: "9999999999",position: "relative",top: "-10px",cursor: "pointer",}}>✖</span>
                </div>
      {
        !loading ? (
          <div className="flex-[0.50]" style={{display: 'flex', alignItems: "center", justifyContent: "center"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center",justifyContent: "center", width: (window.innerWidth<760)?"85vw":"418px", borderRadius: "8px", background: "white", height: "480px"}}>
                <div style={{flex: "0.75", display: "flex", flexDirection: "column", justifyContent: 'space-around'}}>
                {message.text && (
                  <div style={{ color: message.type === "error" ? "red" : "green", textAlign: "center", marginTop: "10px" }}>
                    {message.text}
                  </div>
                )}
                    <input onChange={(e)=>setName(e.target.value)} type="text" placeholder="Name" style={{border: "0.5px solid grey", borderRadius: "8px", outline: "none", width: (window.innerWidth<760)?"75vw":"380px", padding: "12px", height: "50px"}}/>
                    <input onChange={(e)=>setCompanyName(e.target.value)} type="text" placeholder="Company Name" style={{border: "0.5px solid grey", borderRadius: "8px", outline: "none", width: (window.innerWidth<760)?"75vw":"380px", padding: "12px", height: "50px"}}/>
                    <input onChange={(e)=>setCompanyEmail(e.target.value)} type="email" placeholder="Company Email" style={{border: "0.5px solid grey", borderRadius: "8px", outline: "none", width: (window.innerWidth<760)?"75vw":"380px", padding: "12px", height: "50px"}}/>
                    <input onChange={(e)=>setContactNumber(e.target.value)} type="number" placeholder="Contact Number" style={{border: "0.5px solid grey", borderRadius: "8px", outline: "none", width: (window.innerWidth<760)?"75vw":"380px", padding: "12px", height: "50px"}}/>
                    <input onChange={(e)=>setRequirements(e.target.value)} type="text" placeholder="Tell us about your requirements" style={{border: "0.5px solid grey", borderRadius: "8px", width: (window.innerWidth<760)?"75vw":"380px", outline: "none", padding: "12px", height: "50px"}}/>
                </div>
                <div onClick={handleSave} style={{background: "black",cursor: "pointer", fontSize: "18px", fontWeight: "600", width: (window.innerWidth<760)?"200px":"260px", padding: "8px", textAlign: "center", color: "white", borderRadius: "30px", marginTop: "2rem"}}>Book a Demo</div>
            </div>
          </div>
        ) : (
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: (window.innerWidth<760) ? "70vh":"418px", width: (window.innerWidth<760) ? "85vw":"380px", flexDirection: "column"}}>
            {
              success ? (
                <><div style={{textAlign: "center", height: '10%'}}><img src="/assets/successBlink.png" style={{height: "100%"}} alt=""/></div>
            <div style={{textAlign: "center", marginTop: "1rem"}}>THANK YOU <br/> FOR YOUR INTEREST <br/> <hr style={{"borderTopWidth": "3px"}}/>We will connect with you shortly.</div></>
              ) : (
                <div style={{textAlign: "center"}}><img src="/assets/loadingRipple.gif" alt=""/></div>
              )
            }
          </div>
        )
      }
    </div>
  );
};

export default FormComponent;
